import {useState, useEffect} from 'react'

interface FullDrawerContainerProps {
    isOpen: boolean;
    onClose: () => void;
    width?: string;
    children?: JSX.Element | JSX.Element[];
    closeOnOutsideClick?: boolean; // New prop to control outside click behavior
    padding?: string;
    className?: string;
    overlayClassName?: string;
    
}

export const  FullDrawerContainer = ({
    isOpen,
    onClose,
    width='w-screen',
    children,
    closeOnOutsideClick = true, // Default to true if not provided
    padding='4',
    className='',
    overlayClassName=''
}:FullDrawerContainerProps) => {

    // const overlayClasses = isOpen ? 'opacity-50 pointer-events-auto' : 'opacity-0 pointer-events-none';
    const drawerClasses = isOpen ? 'translate-x-0' : 'translate-x-full';

    // const handleOverlayClick = () => {
    //   if (closeOnOutsideClick) {
    //     onClose();
    //   }
    // };

    //prevent scroll in page when drawer is open
    useEffect(() => {
        const handleBodyScroll = () => {
          // Toggle the "overflow-hidden" class on the body when the drawer is open
          document.body.classList.toggle('overflow-hidden', isOpen);
        };
    
        // Add or remove event listener based on the drawer state
        if (isOpen) {
          document.addEventListener('scroll', handleBodyScroll, { passive: false });
        } else {
          document.body.classList.remove('overflow-hidden'); // Ensure the class is removed
          document.removeEventListener('scroll', handleBodyScroll);
        }
    
        // Cleanup the event listener when the component unmounts
        return () => {
          document.body.classList.remove('overflow-hidden'); // Ensure the class is removed on unmount
          document.removeEventListener('scroll', handleBodyScroll);
        };
      }, [isOpen]);


    return (
        <>
            {/** OVERLAY COMPONENT */}
            {/* <div
                onClick={handleOverlayClick}
                className={`fixed top-0 left-0 w-full h-full bg-black ${overlayClasses} transition-opacity ease-in-out duration-300 z-50 ${overlayClassName}`}
            ></div> */}

            {/** DRAWER COMPONENT */}
            <div className={`fixed top-0 right-0 h-[100dvh] overflow-y-scroll ${width} border-l bg-white p-${padding} transform ${drawerClasses} transition-transform ease-in-out duration-300 z-[51] ${className}`}>
                {children}
            </div>
      </>
    )
}